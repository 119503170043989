import styled from 'styled-components';

export const Footer = styled.footer`
  padding: 0px 0px;
`;

export const Subscription = styled.div`
  display: flex;
  margin-top: 200px;
  justify-content: center;
  padding: 0px 8px;

  & > input {
    font-weight: 600;
    max-width: 500px;
    width: 100%;
    height: 100px;
    color: ${({theme}) => theme.palatte.text.default};
    font-size: 1.6em;
    font-style: italic;
    padding: 0px 20px;
  }

  & > button {
    font-weight: 600;
    border: 1px solid ${({theme}) => theme.palatte.text.default};
    background: ${({theme}) => theme.palatte.text.default};
    color: #fff;
    font-size: 1.6em;
    width: 230px;
  }

  @media (max-width: 600px) {
    & > input {
      height: 70px;
      font-size: 1.2em;
      font-style: italic;
    }

    & > button {
      font-weight: 600;
      font-size: 1.2em;
    }
  }
`;

export const Connect = styled.div`
  background-image: url('/images/dot-icon.png');
  margin-top: 2rem;

  & > div {
    padding: 1em;
    margin: auto;
    max-width: 1000px;
    width: calc(100% - 2em);
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > .connect {
      & > h1 {
        font-size: 3rem;
        margin: 0px;
      }

      & > .users {
        margin-top: 1em;
        display: flex;
        margin-left: 1em;

        & > img:first-child {
          z-index: 50;
        }
        & > img:nth-child(2) {
          z-index: 40;
        }

        & > img {
          height: 48px;
          width: 48px;
          border-radius: 48px;
          margin-left: -16px;
          border: 4px solid #fff;
        }
      }
    }

    & > .started {
      & > button {
        display: flex;
        justify-content: center;
        gap: 0.5em;
        border: none;
        padding: 0.8em 1.2em;
        border-radius: 4em;
        font-family: Thicccboi, sans-serif;
        font-size: 1.8em;
        font-weight: 500;
        color: #fff;
        background: ${({theme}) => theme.palatte.text.default};
      }
      & > button:active {
        color: ${({theme}) => theme.palatte.text.default};
        background: #fff;
        border: 1px solid ${({theme}) => theme.palatte.text.default};
      }
    }
  }

  @media (max-width: 600px) {
    & > div {
      padding: 1em;
      flex-direction: column;
      justify-content: space-around;

      
      & > .connect > .users {
        justify-content: center;
      }
    }
  }
`;

export const FooterSection = styled.div`
  min-height: 600px;
  height: 100%;
  background: ${({theme}) => theme.palatte.text.default};
  margin-top: 200px;
  padding: 60px 16px 0px 16px;

  & > div {
    margin: auto;
    display: flex;
    justify-content: space-between;
    letter-spacing: 0.1em;
    max-width: 1000px;
    flex-wrap: wrap;
  }

  & > div > div {
    @media (max-width: 600px) {
      width: 50%;
    }
  }

  & > div * p {
    font-weight: 300;
    margin: 16px 0px;
    color: #fff;
    font-size: 0.875rem;
  }

  & > div * h2 {
    font-weight: 400;
    font-size: 2em;
    margin: 30px 0px;
    color: #fff;
  }

  & > div * h3 {
    font-weight: 500;
    font-size: 1.125em;
    margin: 24px 0px;
    color: #fff;
  }

  & > div * h4 > a {
    color: #e5fbfc;
    text-decoration: none;
  }
  & > div * h4 {
    font-weight: 300;
    font-size: 0.875rem;
    margin: 16px 0px;
    color: #e5fbfc;
  }

  & > div * hr {
    width: 100%;
    margin: 40px 0px 0px 0px;
  }

  & > div > div > div.footer-emails {
  }
  & > div > div > div {
    display: flex;
    // justify-content: space-between;
    gap: 30px;

    @media (max-width: 600px) {
      flex-direction: column;
    }
  }

  & > div > div > div > div {
    // margin-right: 60px;
  }

  & > li {
    display: inline;
    font-size: 1.125em;
    margin: 0px 20px;
    font-weight: 500;
  }
`;

export const FooterBottom = styled.div`
  min-height: 80px;
  height: 100%;
  background: ${({theme}) => theme.palatte.text.default};
  padding: 24px 16px 32px 16px;
  letter-spacing: 0.1em;

  & > div {
    max-width: 1000px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${({theme}) => theme.palatte.text.contrast};
    padding: 24px 0px;
    font-size: 14px;
    font-weight: 300;

    .social {
      min-width: 160px;
      display: flex;
      justify-content: space-between;
    }

    ul {
      display: inline;
      margin: 0px;
      padding: 0px;
    }

    ul li {
      display: inline;
      padding-left: 16px;
    }

    ul li a {
      text-decoration: none;
      color: ${({theme}) => theme.palatte.text.contrast};
    }

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;

      .social {
        margin-top: 32px;
        margin-bottom: 8px;
      }

      ul li {
        padding-left: 0px;
        padding: 8px;
      }
    }
  }
`;
