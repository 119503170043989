import React, { useRef, useEffect, useState } from 'react';
import Header from '../Header';
import { ThemeProvider } from 'styled-components';
import theme from '../Theme/theme';
import GlobalStyle from '../Theme';

import { Helmet } from 'react-helmet';
import { LayoutWrapper } from './styled';

const isBrowser = typeof window !== 'undefined';

const Layout = ({ children, description, title, metaImage, url = "https://zegates.com" }) => {
  const windowSize = useWindowSize();
  const scrollingContainerRef = useRef();
  const data = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0,
  };

  useEffect(() => {
    // setBodyHeight();
  }, [windowSize.height]);

  const setBodyHeight = () => {
    document.body.style.height = `${scrollingContainerRef.current.getBoundingClientRect().height
      }px`;
  };

  useEffect(() => {
    // requestAnimationFrame(() => smoothScrollingHandler());
  }, []);

  const smoothScrollingHandler = () => {
    data.current = window.scrollY;
    data.previous += (data.current - data.previous) * data.ease;
    data.rounded = Math.round(data.previous * 100) / 100;

    scrollingContainerRef.current.style.transform = `translateY(-${data.rounded}px)`;

    // Recursive call
    requestAnimationFrame(() => smoothScrollingHandler());
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <main>
        <Helmet>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link
            href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap"
            rel="stylesheet"
          />

          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="manifest" href="/site.webmanifest" />
          <meta charSet="utf-8" />
          <meta name="description" content={description || meta.description} />
          <meta
            name="keywords"
            content="zegates, zegates international,software engineering sri lanka, sri lanka, software product, development sri lanka, software engineers sri lanka, mobile apps sri lanka, web development, platform, data platforms, big data sri lanka"
          />
          {metaImage ? (
            <meta
              name="image"
              property="og:image:secure_url"
              content={metaImage}
            />
          ) : null}
          {metaImage ? (
            <meta
              name="image"
              property="og:image"
              content={metaImage}
            />
          ) : null}
          {url ? (
            <meta
              name="url"
              property="og:url"
              content={url}
            />
          ) : null}

          <meta
            name="title"
            property="og:title"
            content={title ||
              'Zegates International LTD - Software Product Engineering'}
          />
          <meta
            name="type"
            property="og:type"
            content={'website'}
          />

          <meta
            property="og:description"
            content={description || meta.description}
          />
          <meta
            property="og:title"
            content={title ||
              'Zegates International LTD - Software Product Engineering'}
          />
          <meta name="robots" content="index, follow" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="language" content="English" />
          <title>
            {title ||
              'Zegates International LTD - Software Product Engineering'}
          </title>
          <link rel="canonical" href="https://zegates.com" />
        </Helmet>
        <Header />
        {/* <CookieConsent
          location="bottom"
          buttonText="Sure man!!"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        > */}

        {/* </CookieConsent> */}
        <LayoutWrapper>
          <div ref={scrollingContainerRef}>{children}</div>
        </LayoutWrapper>
      </main>
    </ThemeProvider>
  );
};

export default Layout;

const useWindowSize = () => {
  const getSize = () => {
    if (isBrowser) {
      return {
        width: window.innerWidth,
        height: window.innerHeight,
      };
    }
    return {
      width: 800,
      height: 800,
    };
  };

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

const meta = {
  description:
    '"Zegates have been providing software engineering services from 2017 with the headquarters situated at Colombo."',
};
