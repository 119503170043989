import React, { useCallback, useEffect, useState } from 'react';
import { PopupModal } from "react-calendly";
import { Nav, Navigation, LogoWrapper, ShowMenuIcon, MenuList, MenuIconWrapper, MenuListWrapper } from './styled'
import Logo from '../../images/logoz.svg';
import Fade from 'react-reveal/Fade';

const Header = () => {
  const [offset, setOffset] = useState(0);
  const [show, setShow] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showCalendly, setShowCalendly] = useState(false);

  const onScroll = useCallback(() => {
    if ((offset > window.pageYOffset || window.pageYOffset < 200) !== show) {
      setShow(offset > window.pageYOffset || window.pageYOffset < 200);
    }
    setOffset(window.pageYOffset);
  }, [offset]);

  useEffect(() => {
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);


  const onResize = (event) => {
    // if (event && event.currentTarget) {
    if (window.innerWidth > 600) {
      setShowMenu(true);
    } else {
      setShowMenu(false);
    }
    // }
  }

  useEffect(() => {
    window.removeEventListener('resize', onResize);
    window.addEventListener('resize', onResize);

    onResize();
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const onPressMenu = () => {
    setMenuOpen(!menuOpen);
  }

  return (
    <Nav colored={true}>
      <PopupModal
        url="https://calendly.com/zegates/zegates-client-meeting"
        onModalClose={() => setShowCalendly(false)}
        open={showCalendly}
        pageSettings={{
          hideEventTypeDetails: false,
          hideLandingPageDetails: true,
        }}
        rootElement={typeof document !== "undefined" && document.getElementById("___gatsby")}
      />
  
      <Fade duration={300} top collapse when={show}>
        <div>
          <LogoWrapper href="/">
            <div>
              <Logo />
              <div>
                <h1>ZEGATES</h1>
                <span>We think beyond</span>
              </div>
            </div>
          </LogoWrapper>
          <ShowMenuIcon href="#" onClick={() => setShowMenu(!showMenu)}>
            <MenuIconWrapper>
              <div className='menu-icon-wrapper'>
                <div className={`menu-icon ${showMenu ? 'open' : ''}`}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </MenuIconWrapper>
          </ShowMenuIcon>
          <MenuListWrapper>
            <MenuList style={{ display: showMenu ? 'flex' : 'none' }}>
              <Fade distance={'80px'} duration={200} bottom cascade>
                <Navigation>
                  <li><a href="/start-project">Start Project</a></li>
                  <li><a href="/services">Services</a></li>
                  <li><a href="/projects">Projects</a></li>
                  <li><a href="/about">About</a></li>
                  {/* <li>Resources</li> */}
                  <li><a href="/blog">Blog</a></li>
                  {/* <li>Careers</li> */}
                </Navigation>
              </Fade>
              <div className="close" onClick={() => setShowMenu(false)}>
                {/* <MenuIconWrapper>
                <div className='menu-icon-wrapper'>
                  <div className={`menu-icon ${menuOpen ? 'open' : ''}`} onClick={onPressMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </MenuIconWrapper> */}
              </div>
            </MenuList>
            <button className='contact-us' onClick={() => setShowCalendly(true)}>
              Let's Meet
            </button>
          </MenuListWrapper>

          {/* <MenuIconWrapper>
            <div className='menu-icon-wrapper'>
              <div className={`menu-icon ${menuOpen ? 'open' : ''}`} onClick={onPressMenu}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </MenuIconWrapper> */}
        </div>

      </Fade>
    </Nav >
  )
}

export default Header;
