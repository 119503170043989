import {createGlobalStyle, css} from 'styled-components';

const GlobalStyle = createGlobalStyle`
  ${
  ({theme}) => css`
    body {
      font-family: ${theme.font};
      color: ${theme.palatte.text.default}; 
      margin: 0px;
    }
    input {
      font-family: ${theme.font};
    }
    button {
      font-family: ${theme.font};
    }
  `
  }
`;

export default GlobalStyle;
