import React, {useState} from 'react';
import {
  Footer,
  Subscription,
  FooterSection,
  FooterBottom,
  Connect,
} from './styled';
import {PopupModal} from 'react-calendly';

const FooterComponent = () => {
  const [showCalendly, setShowCalendly] = useState(false);

  return (
    <Footer>
      <PopupModal
        url="https://calendly.com/zegates/zegates-client-meeting"
        onModalClose={() => setShowCalendly(false)}
        open={showCalendly}
        pageSettings={{
          hideEventTypeDetails: false,
          hideLandingPageDetails: true,
        }}
        rootElement={
          typeof document !== 'undefined' &&
          document.getElementById('___gatsby')
        }
      />

      <Connect>
        <div>
          <div className="connect">
            <h1 className="font-bold my-8">Let’s Connect</h1>
            <div className="users">
              <img
                className="u-first"
                src="/images/users/sandaruwan_nanayakkara_thumb.jpg"
              />
              <img src="/images/users/malitha_thumb.jpg" />
              <img src="/images/users/madhushanka_thumb.jpg" />
            </div>
          </div>
          <div className="started ">
            <button
              onClick={() => setShowCalendly(true)}
              className="flex items-center justify-center"
            >
              <img src="/images/icons/top-arrow.svg" alt="" />
              Let's Meet
            </button>
          </div>
        </div>
      </Connect>
      <Subscription>
        <input className="border border-default" />
        <button>Subscribe</button>
      </Subscription>
      <FooterSection>
        <div>
          <div>
            <h3>Industries</h3>
            <h4>
              <a href="/consumer-markets">Consumer Market</a>
            </h4>
            <h4>
              <a href="/real-estate">Real Estate</a>
            </h4>
            <h4>
              <a href="/retail-crm">Retail and CRM</a>
            </h4>
            {/* <h4>Education</h4> */}
          </div>

          <div>
            <h3>Services</h3>
            <h4>
              <a href="/services/data-analytics">Data and Analytics</a>
            </h4>
            <h4>
              <a href="/services/artificial-intelligence">
                Artificial Intelligence
              </a>
            </h4>
            <h4><a href="/services/iot">IoT</a></h4>
            <h4>Security</h4>
            <h4>AWS Cloud</h4>
            <h4>Azure Cloud</h4>
            <h4>IBM Cloud</h4>
            <h4>Enterprise</h4>
          </div>
          <div>
            <h3>Platforms</h3>
            <h4>Statuz 🎛️</h4>
            <h4>Netlise 360 System</h4>
            <h4>Expert Partners 🚀</h4>
          </div>
          <div>
            <h3>Insights</h3>
            <h4>
              <a href="https://zegates.com/blog">Blog</a>
            </h4>
            <h4>Startup</h4>
            <h4>Technology</h4>

            <h3>Company</h3>
            <h4>Careers</h4>
            <h4>
              <a href="/about">About</a>
            </h4>
          </div>
        </div>
        <div>
          <div style={{width: '100%'}}>
            <div>
              <div>
                <hr />
                <h3>Sri Lanka</h3>
                <p>+94 112 512 600</p>
                <p>
                  13/22 Averihena rd, Polhengoda
                  <br />
                  Colombo 05
                </p>
              </div>
              <div>
                <hr />
                <h3>USA</h3>
                <p>
                  9169 W STATE ST #273
                  <br />
                  GARDEN CITY, ID 83714
                </p>
              </div>
              <div className="footer-emails">
                <hr />
                <h3>Stay in touch</h3>
                <p>Zegates International Ltd</p>
                <p>corporate@zegates.com</p>
              </div>
            </div>
          </div>
        </div>
      </FooterSection>
      <FooterBottom>
        <div>
          <img
            src="/images/icons/zegates-small.svg"
            alt="Zegates International LTD"
          />
          <div className="social">
            <a
              href="https://www.instagram.com/zegates_international"
              target="_blank"
            >
              <img src="/images/icons/instagram.svg" alt="Zegates Instagram" />
            </a>
            <a href="https://www.facebook.com/zegates.soft" target="_blank">
              <img src="/images/icons/facebook.svg" alt="Zegates Facebook" />
            </a>
            <a href="https://www.linkedin.com/company/zegates/" target="_blank">
              <img src="/images/icons/linkedin.svg" alt="Zegates LinkedIn" />
            </a>
          </div>
          <p>© 2024 Zegates International LTD</p>
          <ul>
            <li>Privacy Policy</li>
            <li>
              <a href="#" class="cky-banner-element">
                Cookie Policy
              </a>
            </li>
          </ul>
        </div>
      </FooterBottom>
    </Footer>
  );
};

export default FooterComponent;
