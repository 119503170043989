const theme = {
  palatte: {
    text: {
      default: '#293241',
      contrast: '#E5FBFC',
      highlight: '#e73c7e',

    },
    background: {
      light: '#F7F7F7',
      contrast: '#E5FBFC',
      highlight: '#DF7356',
    },
    colors: {
      blue: '#3D6BFB',
      green: '#004D53',
      orange: '#DF7356',
      yellow: '#FDFEC9',
      thickyellow: '#FDF47B',
      lightblue: '#C4F4FF',
      lilly: '#FFB8A5',
      lightgreen: '#BAFFB4',
    }
  },

  font: `'Open Sans', sans-serif;`,
  text: {
    p: '1.125em', 
  }
};

export default theme;
