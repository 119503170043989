import styled from "styled-components";

export const LayoutWrapper = styled.div`
  // position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
